import React, { useEffect } from "react";
import { Element, animateScroll as scroll } from "react-scroll";
import Line from "../../../Base/Line";

const projectList = [
  {
    id: "1",
    text: "Join projects that interest you",
  },
  {
    id: "2",
    text: "Grow your portfolio",
  },
  {
    id: "3",
    text: "Get noticed by patential customers",
  },
  {
    id: "4",
    text: "Gain recongninition for you projects",
  },
  {
    id: "5",
    text: "Find exceptional talent",
  },
];
const servicesList = [
  {
    id: "1",
    text: "Minimals fee",
  },
  {
    id: "2",
    text: "Make money",
  },
  {
    id: "3",
    text: "Grow your reputation",
  },
  {
    id: "4",
    text: "Get your creative work done",
  },
];
const eventsList = [
  {
    id: "1",
    text: "Target directly to a creative audience",
  },
  {
    id: "2",
    text: "Sell your products & merch right on your event page",
  },
  {
    id: "3",
    text: "Anti-scam protection system. Share your experiense with your friends",
  },
  {
    id: "4",
    text: "Buy ditectly from favorite artists, galleries, etc",
  },
];
const productsList = [
  {
    id: "1",
    text: "Sell  your digital or physical services",
  },
  {
    id: "2",
    text: "Find handcrafted goods by creator",
  },
];

export default function Main() {
  return (
    <div className="aboutMain">
      <div className="aboutMain__inner">
        <div className="anchor2" id="projects"></div>
        <Element className="anchor" name="projects"></Element>
        {projectList.map((item, index) => {
          return <AboutItem {...item} key={index} />;
        })}
      </div>
      <div className="aboutMain__inner">
        <div className="anchor2" id="services"></div>
        <Line delay={0.2} className="aboutLine"></Line>
        <Element className="anchor" name="services"></Element>
        {servicesList.map((item, index) => {
          return <AboutItem {...item} key={index} />;
        })}
      </div>
      <div className="aboutMain__inner ">
        <div className="anchor2" id="events"></div>
        <Line delay={0.2} className="aboutLine"></Line>
        <Element className="anchor" name="events"></Element>
        {eventsList.map((item, index) => {
          return <AboutItem {...item} key={index} />;
        })}
      </div>
      <div className="aboutMain__inner">
        <div className="anchor2" id="products"></div>
        <Line delay={0.2} className="aboutLine"></Line>
        <Element className="anchor" name="products"></Element>
        {productsList.map((item, index) => {
          return <AboutItem {...item} key={index} />;
        })}
      </div>
    </div>
  );
}
const AboutItem = (props) => {
  return (
    <div className="aboutItem">
      <h3>
        {props.id} {props.text}
      </h3>
    </div>
  );
};
