import React, { useEffect } from "react";
import Side from "./Side";
import Main from "./Main";

export default function About() {
  return (
    <>
      <div className="aboutA" id="about"></div>

      <div className="about">
        <div className="auto__container">
          <div className="about__inner">
            <Side />
            <Main />
          </div>
        </div>
      </div>
    </>
  );
}
