import React from "react";

export default function Footer({ setModal, modal }) {
  return (
    <>
      <div className={"footer " + (modal ? "active" : "")}>
        <div className="footer__bg">
          <img
            src={process.env.PUBLIC_URL + "/images/footWeb.webp"}
            alt="foot"
          />
          <img
            className="mobile"
            src={process.env.PUBLIC_URL + "/images/footWebM.webp"}
            alt="foot"
          />
        </div>
        <div className="auto__container">
          <div className="footer__inner">
            <div className="footer__inner-image">
              <img
                src={process.env.PUBLIC_URL + "/images/foot/footPc.svg"}
                alt="footPc"
              />
              <img
                className="footerM"
                src={process.env.PUBLIC_URL + "/images/foot/footM.svg"}
                alt="footM"
              />
            </div>
            <div
              className="modalBtn"
              onClick={() => {
                setModal(true);
              }}
            >
              <span> Submit an application</span>
            </div>
          </div>
          <div className="footer__inner-foot">
            <p>©2024, LikeMinded</p>
            <p>
              <a href="info@likeminded.net">info@likeminded.net</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
