import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";

export default function AltMask({ children, delay, className }) {
  return (
    <motion.span
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: "0" }}
      transition={{ duration: 0.4, delay: delay }}
      exit={{
        opacity: 0,
        y: "100%",
        transition: { delay: 0, duration: 0.4 },
      }}
      className="maskText"
    >
      {children}
    </motion.span>
  );
}
