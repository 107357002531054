"use client";
import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { motion } from "framer-motion";

export default function Modal({ setModal, modal }) {
  const [error, setError] = useState(false);
  const closeModal = (e) => {
    if (e.target === e.currentTarget) setModal(null);
  };
  const [form, setForm] = useState({
    email: "",
    checked: false,
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const handleClick = () => {
    if (form.email === "" || form.checked === false) {
      setError(true);
    } else {
      setError(false);
      setModal(false);
    }
  };

  return ReactDom.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0 }}
      className="modal"
      onClick={closeModal}
    >
      <div className="auto__container">
        <div className="modal__inner">
          <div className="modal__inner-form">
            <div className={"modalInput " + (error ? "error" : "")}>
              <input
                type="email"
                placeholder="EMAIL"
                value={form.email}
                onChange={onChangeInput("email")}
              />
            </div>
            <div className="modalCheck">
              <input
                type="checkbox"
                checked={form.checked}
                onChange={(e) => {
                  updateForm({ checked: e.target.checked });
                }}
              />
              <label htmlFor="">
                I agree with the <a href="#">privacy policy</a>
              </label>
            </div>
            <button type="button" className="modalBtn" onClick={handleClick}>
              <span> Submit an application</span>
            </button>
          </div>
          <div className="modal__inner-foot">
            <p>©2024, LikeMinded</p>
            <p>
              <a href="info@likeminded.net">info@likeminded.net</a>
            </p>
          </div>
        </div>
      </div>
    </motion.div>,
    document.getElementById("popups")
  );
}
