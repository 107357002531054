import React from "react";
import Crack from "../../Base/Crack";
import { motion } from "framer-motion";
import Mask from "../../Base/Mask";

export default function Intro() {
  return (
    <div className="intro">
      <div className="intro__bg">
        <img src={process.env.PUBLIC_URL + "/images/web.webp"} alt="web" />
        <img
          className="mobile"
          src={process.env.PUBLIC_URL + "/images/webM.webp"}
          alt="web"
        />
      </div>
      <div className="auto__container">
        <div className="intro__inner">
          <div className="intro__inner-image">
            <motion.img
              initial={{ scale: 1.1, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              src={process.env.PUBLIC_URL + "/images/intro.webp"}
              alt="png"
              className="introPng"
            />
          </div>
          <div className="intro__inner-content">
            <h1>
              <Mask delay={0.2}>A creative </Mask>
              <Mask delay={0.4} className={"gray"}>
                network{" "}
              </Mask>
            </h1>
            <div className="intro__inner-content-image">
              <motion.img
                initial={{ scale: 1.1, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5 }}
                src={process.env.PUBLIC_URL + "/images/intro.webp"}
                alt="png"
              />
            </div>
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7, duration: 0.3 }}
            >
              A meeting place for ideas and creativity.{" "}
            </motion.h2>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.9, duration: 0.3 }}
            >
              A place where creators can realize their ideas, and performers can
              tell the world about themselves.
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
}
