import React, { useEffect, useState } from "react";
import { closeIcon } from "./SVG";

export default function Header({ setModal, modal }) {
  const [isSticky, setIsSticky] = useState(false);
  const start = 120;
  const onScroll = () => {
    let anchor3 = document.getElementById("anchor3");
    if (window.scrollY > anchor3.offsetTop) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);
  return (
    <div className={"header " + (isSticky || modal ? "sticky" : "")}>
      <div className="auto__container">
        <div className="header__inner">
          <div className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" />
          </div>
          <div className="header__inner-links">
            <button
              type="button"
              className="header__link"
              onClick={() => setModal(!modal)}
            >
              {!modal ? (
                <div className="header__link-wrap">
                  <span className="header__link-title">Join closed beta</span>
                  <span className="header__link-shadow">Join closed beta</span>
                </div>
              ) : (
                <div className="header__link-close">{closeIcon}</div>
              )}
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
