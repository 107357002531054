import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";

export default function Mask({ children, delay, className }) {
  const ref = useRef(null);
  const isInview = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInview) {
      controls.start("visible");
    }
  }, [isInview]);
  return (
    <div className={className + " " + "mask"}>
      <motion.span
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: "100%" },
          visible: { opacity: 1, y: "0" },
        }}
        transition={{
          type: "spring",
          duration: 0.2,
          damping: 15,
          delay: delay,
          stiffness: 100,
        }}
        initial="hidden"
        animate={controls}
        className="maskText"
      >
        {children}
      </motion.span>
    </div>
  );
}
