import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./Base/Header";
import Home from "./Home/Home";
import { AnimatePresence } from "framer-motion";
import Modal from "./Base/Modal";
import Footer from "./Base/Footer";
export default function App() {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);
  return (
    <>
      <Header setModal={setModal} modal={modal} />
      <main className="main">
        <Routes>
          <Route path="" element={<Home setModal={setModal} />} />
        </Routes>
      </main>
      <Footer setModal={setModal} modal={modal} />
      <AnimatePresence>
        {modal && <Modal setModal={setModal} modal={modal} />}
      </AnimatePresence>
    </>
  );
}
