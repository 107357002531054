import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
import Mask from "../../../Base/Mask";

export default function Side() {
  const ref = useRef(null);
  const isInview = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInview) {
      controls.start("visible");
    }
  }, [isInview]);
  return (
    <div className="aboutSide">
      <motion.div
        ref={ref}
        variants={{
          hidden: { height: 0 },
          visible: { height: "100%" },
        }}
        transition={{
          type: "spring",
          duration: 10,
          damping: 8,
          delay: 0.5,
          stiffness: 100,
        }}
        initial="hidden"
        animate={controls}
        className="aboutSide__line"
      ></motion.div>
      <div className="anchorL" ref={ref}></div>
      <div className="aboutSide__links">
        <div
          className="aboutSide__link-outer"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("projects").scrollIntoView();
          }}
        >
          <Link
            to="projects"
            href="#projects"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            className="aboutSide__link"
          >
            Projects
          </Link>
        </div>
        <div
          className="aboutSide__link-outer"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("services").scrollIntoView();
          }}
        >
          <Link
            to="services"
            href="#services"
            spy={true}
            smooth={true}
            duration={500}
            className="aboutSide__link"
          >
            Services
          </Link>
        </div>

        <div
          className="aboutSide__link-outer"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("events").scrollIntoView();
          }}
        >
          <Link
            to="events"
            href="#events"
            spy={true}
            smooth={true}
            duration={500}
            className="aboutSide__link"
          >
            Events
          </Link>
        </div>

        <div
          className="aboutSide__link-outer"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("products").scrollIntoView();
          }}
        >
          <Link
            to="products"
            href="#products"
            spy={true}
            smooth={true}
            duration={500}
            className="aboutSide__link"
          >
            Products
          </Link>
        </div>
      </div>
      <div className="aboutSide__foot">
        <Link
          to="projects"
          href="#projects"
          spy={true}
          smooth={true}
          duration={500}
          activeClass="active"
          className="aboutSide__foot-text"
        >
          <Mask delay={0.2}> Realize your ideas, find a dream job, </Mask>
          <Mask delay={0.4}> tell the whole world about yourself </Mask>
        </Link>
        <Link
          to="services"
          href="#services"
          spy={true}
          smooth={true}
          duration={500}
          activeClass="active"
          className="aboutSide__foot-text"
        >
          <Mask delay={0.2}> Realize yourself as a specialist, </Mask>
          <Mask delay={0.4}> complete the tasks set </Mask>
        </Link>
        <Link
          to="events"
          href="#events"
          spy={true}
          smooth={true}
          duration={500}
          activeClass="active"
          className="aboutSide__foot-text"
        >
          <Mask delay={0.2}> Organize, promote, and attend private </Mask>
          <Mask delay={0.4}> and unique events from all over the world </Mask>
        </Link>
        <Link
          to="products"
          href="#products"
          spy={true}
          smooth={true}
          duration={500}
          activeClass="active"
          className="aboutSide__foot-text"
        >
          <Mask delay={0.2}>Sell, buy and promote unique products</Mask>
        </Link>
      </div>
    </div>
  );
}
