import React, { useEffect, useRef, useState } from "react";
import {
  AnimatePresence,
  motion,
  useTransform,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import Mask from "../../Base/Mask";
import AltMask from "../../Base/AltMask";

export default function Hor() {
  const [isScroll, setIsScroll] = useState(false);
  const component = useRef(null);
  const slider = useRef();
  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [panel4, setPanel4] = useState(false);
  const [back, setBack] = useState(false);
  const onSceneScroll = () => {
    let anchor3 = document.getElementById("anchor3");
    let anchor4 = document.getElementById("anchor4");
    if (
      window.scrollY > anchor3.offsetTop &&
      window.scrollY < anchor4.offsetTop
    ) {
      setBack(true);
    } else {
      setBack(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onSceneScroll);
  }, []);
  const { scrollYProgress } = useScroll({
    target: component,
  });
  const x = useTransform(scrollYProgress, [0, 1], ["0%", "-75%"]);

  useMotionValueEvent(x, "change", (latest) => {
    if (scrollYProgress?.current >= 0.17) {
      setPanel2(true);
      setPanel1(false);
    }
    if (scrollYProgress?.current < 0.17) {
      setPanel1(true);
      setPanel2(false);
    }
    if (scrollYProgress?.current >= 0.5) {
      setPanel3(true);
      setPanel2(false);
    }
    if (scrollYProgress?.current < 0.5 && scrollYProgress?.current >= 0.17) {
      setPanel2(true);
      setPanel3(false);
    }
    if (scrollYProgress?.current >= 0.8) {
      setPanel4(true);
      setPanel3(false);
    }
    if (scrollYProgress?.current < 0.8 && scrollYProgress?.current >= 0.5) {
      setPanel3(true);
      setPanel4(false);
    }
  });

  return (
    <div className="scene hor" ref={component}>
      {back && (
        <div className="sceneBack">
          <div className="auto__container">
            <div className="sceneBack__inner">
              <div>
                <Mask delay={0.2}>
                  What we've <br />
                </Mask>
                <Mask delay={0.4}>
                  been <span>working on </span>
                </Mask>
              </div>

              <Mask delay={0.6}>We're all part of something bigger</Mask>
              <div
                className="sceneSkip"
                onClick={() => {
                  let pos = document.getElementById("about").offsetTop;
                  window.scrollTo(0, pos);
                  if (!isScroll) {
                    setTimeout(() => {
                      window.scrollTo(0, pos);
                    }, 500);
                  }

                  setIsScroll(true);
                }}
              >
                skip
              </div>
            </div>
          </div>
        </div>
      )}
      {back && (
        <div className="panel__text">
          <h3>
            <div className="mask">
              <AnimatePresence>
                {panel1 && <AltMask delay={0.2}>Millions of ideas</AltMask>}
              </AnimatePresence>
              <AnimatePresence>
                {panel2 && (
                  <AltMask delay={0.2}>
                    With other creatives, potential employees,
                  </AltMask>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {panel3 && (
                  <AltMask delay={0.2}>
                    Make a living doing what you love
                  </AltMask>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {panel4 && (
                  <AltMask delay={0.2}>Yourself as a brand, business,</AltMask>
                )}
              </AnimatePresence>
            </div>
            <div className="mask">
              <AnimatePresence>
                {panel1 && <AltMask delay={0.4}>and opportunities</AltMask>}
              </AnimatePresence>
              <AnimatePresence>
                {panel2 && (
                  <AltMask delay={0.4}>
                    people that align with your interests
                  </AltMask>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {panel3 && (
                  <AltMask delay={0.4}>
                    Participate in projects that interest you
                  </AltMask>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {panel4 && (
                  <AltMask delay={0.4}>or get your idea to fruition</AltMask>
                )}
              </AnimatePresence>
            </div>
          </h3>
        </div>
      )}
      <div className="sceneInner">
        <motion.div style={{ x }} ref={slider} className="container">
          <div className="panel ">
            <div className="panel__title">Discover</div>
          </div>
          <div className="panel ">
            <div className="panel__title">Connect</div>
          </div>
          <div className="panel ">
            <div className="panel__title">Earn</div>
          </div>
          <div className="panel ">
            <div className="panel__title">Grow</div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
