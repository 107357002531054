import React from "react";
import Intro from "./components/Intro";
import About from "./components/About/About";
import Hor from "./components/Hor";

export default function Home({ modal, setModal }) {
  return (
    <>
      <Intro />
      <div className="anchorH" id="anchorH"></div>
      <div className="anchor3" id="anchor3"></div>
      <Hor />
      <div className="anchor4" id="anchor4"></div>
      <About />
    </>
  );
}
