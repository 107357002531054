export const closeIcon = (
  <svg viewBox="0 0 56 56" fill="none">
    <path
      d="M14 42L42 14M14 14L42 42"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
